
import FormItem from './ui/forms/FormItem'

export default {
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object, Date, File],
      default: null
    },

    label: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    },

    validator: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    options: {
      type: Object,
      default: null
    },

    showAsText: {
      type: Boolean,
      default: false
    }
  },

  components: { FormItem },

  data () {
    return {
      isFocused: false
    }
  },

  computed: {
    val: {
      get () {
        return this.value
      },

      set (val) {
        // if (this.validator) {
        //   this.validator.$touch()
        // }
        this.$emit('input', val)
      }
    },

    inputId () {
      return Date.now() + '-' + Math.floor(Math.random() * 1000000)
    }
  },

  methods: {
    onFocus () {
      this.isFocused = true
      this.$emit('focus')
    },

    onBlur () {
      this.isFocused = false
      this.$emit('blur')
    }
  }
}
