<template>
  <form-item
    :label="label"
    :value="val"
    :input-id="inputId"
    :is-focused="isFocused"
    :validator="validator"
    class="w-full">
    <el-input
      v-model="val"
      type="textarea"
      :rows="rows"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :show-word-limit="!!maxlength"
      :resize="resize"
      autosize
      @blur="$emit('blur')">
    </el-input>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    rows: {
      type: Number,
      default: 2
    },

    maxlength: {
      type: [Number, String],
      default: null
    },

    resize: {
      type: String,
      default: 'none'
    }
  }
}
</script>
