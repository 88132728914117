import moment from 'moment'

export default {
  computed: {
    currencies () {
      return [
        { value: '124', label: 'CAD' },
        { value: '840', label: 'USD' }
        // {value: "036", label: "AUD"},
        // {value: "986", label: "BRL"},
        // {value: "756", label: "CHF"},
        // {value: "978", label: "EUR"},
        // {value: "826", label: "GBP"},
        // {value: "344", label: "HKD"},
        // {value: "392", label: "JPY"},
        // {value: "554", label: "NZD"},
      ]
    },

    user () {
      return this.$store.state.app.user
    },

    serverTimeDiff () {
      return this.$store.state.app.serverTimeDiff
    }
  },

  methods: {
    getDistanceFromMe (offer, key = 'createdBy') {
      if (!this.user) return null
      if (parseInt(offer[key].id) === parseInt(this.user.id)) return 0
      const x1 = offer[key]?.geoData?.latitude
      const x2 = offer[key]?.geoData?.longitude
      const y1 = this.user?.geoData?.latitude
      const y2 = this.user?.geoData?.longitude
      return this.calculateDistance(x1, y1, x2, y2) || null
    },

    calculateDistance (lat1, lat2, lon1, lon2) {
      lon1 = lon1 * Math.PI / 180
      lon2 = lon2 * Math.PI / 180
      lat1 = lat1 * Math.PI / 180
      lat2 = lat2 * Math.PI / 180

      const dlon = lon2 - lon1
      const dlat = lat2 - lat1
      const a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2)
      const c = 2 * Math.asin(Math.sqrt(a))
      const r = 6371
      return (c * r)
    },

    changeFileName (file, name) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      const blob = file.slice(0, file.size, file.type)
      const fileName = name + '.' + ext
      return new File([blob], fileName, { type: file.type })
    },

    getUrl (name) {
      const url = location.href
      name = name.replace(/\[\[]/, '\\\\[').replace(/[\]]/, '\\\\]')
      var regexS = '[\\?&]' + name + '=([^&#]*)'
      var regex = new RegExp(regexS)
      var results = regex.exec(url)
      return results == null ? null : results[1]
    },

    isImage (file) {
      var parts = file.name.split('.')
      const ext = parts[parts.length - 1]
      const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(ext)
      if (!isImage) return false
      // this.$message.error('Selected file type is not accepted. Accpted file types are: JPG, JPEG, PNG, GIF')
      return isImage
    },

    getMax (arr) {
      return arr.reduce((a, b) => Math.max(parseInt(a), b))
    },

    agoTimer (source, now) {
      if (typeof (now) === 'number') { now = undefined }
      var t = moment(source)
      var n = moment(now).subtract(this.serverTimeDiff, 's')
      if (n.unix() - t.unix() < 0) { return '00:00:00' }
      var diff = n.unix() - t.unix()
      var h = Math.floor(diff / 3600)
      var m = Math.floor(diff / 60) % 60
      var s = Math.floor(diff) % 60
      return (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s
    }
  }
}
