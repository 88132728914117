<template>
  <form-item
    :label="label"
    :validator="validator"
    :input-id="inputId"
    class="w-full is-checkbox"
    :levitated="false"
    @labelClicked="val = !val">
    <el-checkbox
      v-if="!showAsText"
      v-model="val"
      v-bind="options"
      :id="inputId"
      :placeholder="placeholder"
      :disabled="disabled"
      class="mr-10">
      <slot />
    </el-checkbox>
    <span
      v-else
      class="textual">
      {{ val || 'N/A' }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin]
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }

  .el-checkbox {
    margin-right: 10px !important;
  }
  .is-checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
</style>
