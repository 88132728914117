<template>
  <div id="app">
    <error-box
      v-if="error"
      :loading="loading"
      @reload="reload()" />

    <router-view v-else />
  </div>
</template>

<script>
import ErrorBox from '@/components/common/ui/ErrorBox'

export default {
  components: {
    ErrorBox
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    error () {
      return this.$store.state.app.error
    }
  },

  methods: {
    async reload () {
      this.loading = true
      try {
        await this.$store.dispatch('app/loadData')
      } catch {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    width: 100%;
    height: 100%;
  }
</style>
