import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'

import store from '../store'
import routes from './routes'

Vue.use(VueRouter)

const token = localStorage.getItem('token')
if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit('page/setTabs', [])
  try {
    // await store.dispatch('app/getVersion')
    await store.dispatch('app/loadData')

    const user = store.state.app.user
    const authOnly = to.matched.some(record => record.meta.auth)
    const noAuthOnly = to.matched.some(record => record.meta.auth === false)

    if (!user && authOnly) return next({ name: 'Login' })
    if (noAuthOnly && user) return next({ name: 'Dashboard' })
    next()
    window.scrollTo({ top: 0 })
  } catch (error) {
    next()
  }
})

export default router
