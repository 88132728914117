<template>
  <div class="form-group" :class="{ levitated }">
    <label v-if="label" :class="{ active: levitatedLabel }" :for="inputId" @click="$emit('labelClicked')">{{ label }} <span v-if="isRequired" class="required">*</span></label>
    <div class="input-wrapper" :class="{ error: hasError }">
      <slot />
    </div>
    <span v-if="errors.length && validator" class="errors">
      <span v-for="error in errors" :key="error" class="help-block text-danger">{{ error }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    inputId: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    value: {
      type: [String, Object, Boolean, Array],
      default: null
    },

    isFocused: {
      type: Boolean,
      default: false
    },

    validator: {
      type: Object,
      default: null
    },

    levitated: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      messages: {
        required: 'This field is required.',
        isTruthy: 'This field is required.',
        email: 'This should be a valid email.',
        sameAs: 'Passwords must match.',
        integer: 'The value must be integer.',
        numeric: 'This field must be letters only.',
        between: 'The value is out of range.',
        maxLength: 'Maximum character limit exceeded.',
        alpha: 'This field must be letters only.',
        alphaNum: 'This field does not allow special characters.',
        ipAddress: 'The value must be a valid IPv4 address.',
        url: 'The value must be a valid URL.',
        minLength: 'More characters required.',
        minValue: 'The value can not be less than zero.',
        passwordValidation: 'Password needs to contain small letter, capital letter, number, symbol and at least 6 characters.',
        decimal: 'This field must be a number.',
        alphaNumNoSpace: 'This field must be alphanumeric.',
        alphaOnly: 'This field must be letters only.',
        alphaVersionNoSpace: 'This field does not allow special characters.',
        addressValidation: 'This field does not allow special characters.',
        dashNumbers: 'This field does not allow special characters.',
        limit: 'Amount Entered Exceeds Limit',
        routingNumber: 'Routing number can only start with 0, 1, 2 or 3',
        monbiAccountNumber: 'This is not a valid Account number. Please check information and try again.',
        swift: 'Enter a valid SWIFT code.',
        minLimit: 'The Amount must be greater than the Fee.',
        webSiteUrl: 'This field must be url format.'
      }
    }
  },

  computed: {
    levitatedLabel () {
      return (this.value !== undefined && this.value !== null && this.value !== '') || this.isFocused
    },

    hasError () {
      return this.errors.length > 0
    },

    isRequired () {
      return this.validator && Object.prototype.hasOwnProperty.call(this.validator, 'required')
    },

    errors () {
      if (this.validator && this.validator.$dirty && !this.validator.$pending) {
        return Object.keys(this.validator).filter((prop) => !prop.startsWith('$') && !this.validator[prop]).map((prop) => {
          if (this.validator.$params?.[prop]?.message) {
            return this.validator.$params[prop].message
          }
          return this.messages[prop]
        })
      }

      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  display: block;
  margin: 5px 0 10px 0;
  box-sizing: border-box;
  position: relative;
  .errors {
    display: block;
    min-height: 15px;
    font-size: 14px;
    margin-top: 5px;
    span.text-danger {
      color: #d23131;
    }
  }
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }

  &.levitated {
    label {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      display: block;
      font-size: 16px;
      margin-bottom: 0;
      transition: all ease .2s;
      color: $title;
      &.active {
        top: -8px;
        font-size: 14px;
        color: $text;
      }
    }
  }
}

.form-group + .form-group {
  margin-top: 15px;
}
</style>
