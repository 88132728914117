import Vue from 'vue'
import Vuex from 'vuex'
import factory from './factory'

import app from './app'
import page from './page'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    page,
    users: factory('User'),
    registration: factory('Registration'),
    statements: factory('Statement'),
    transactions: factory('Transactions'),
    account: factory('Account'),
    linkedAccount: factory('LinkedAccount'),
    quotes: factory('Quotes'),
    fee_schedule: factory('FeeSchedule'),
    fileTransfer: factory('FileTransfer'),
    cards: factory('Cards'),
    readed: factory('Readed'),
    discarded: factory('Discarded')
  }
})
