import Vue from 'vue'
import moment from 'moment'
import store from '../store'

const apiUrl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_LOCAL_API_URL : process.env.VUE_APP_API_URL
const serverUrl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_LOCAL_SERVER_URL : process.env.VUE_APP_SERVER_URL

const getColorShade = (hex, lum) => {
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  var rgb = '#'; var c; var i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

const isColorLight = (color) => {
  const hex = color.replace('#', '')
  const cr = parseInt(hex.substr(0, 2), 16)
  const cg = parseInt(hex.substr(2, 2), 16)
  const cb = parseInt(hex.substr(4, 2), 16)
  const brightness = ((cr * 299) + (cg * 587) + (cb * 114)) / 1000
  return brightness > 155
}

window.getColorShade = getColorShade
window.formatDate = (value, format = 'LLL') => {
  if (format === 'calendar') format = 'MM/DD/YYYY'
  if (format === 'calendarFull') format = 'MM/DD/YYYY hh:mm A'
  if (format === 'PD') format = 'dddd, MMMM D YYYY hh:mm:ss A'
  return moment(value).format(format)
}

window.getAccountNumber = (accountNumber) => {
  let number = ''
  for (var i = 0; i < accountNumber.length - 3; i++) {
    number = number + '*'
  }
  number = number + accountNumber.substr(-3)
  return number
}

Vue.prototype.$isColorLight = isColorLight
Vue.prototype.$getColorShade = getColorShade
Vue.prototype.$colors = store.state.app.colors
Vue.prototype.$user = () => store.state.app.user
Vue.prototype.$image = (folder, path) => `${serverUrl}files/${folder}/${path}`
Vue.prototype.$avatar = (image) => `${serverUrl}files/avatars/${image}`
Vue.prototype.$server = serverUrl
Vue.prototype.$api = apiUrl
Vue.prototype.$date = window.formatDate
Vue.prototype.$getAccountNumber = window.getAccountNumber
Vue.prototype.$money = (value, currency = 'USD') => {
  value = value || 0
  return Intl.NumberFormat('en-US', { style: 'currency', minimumFractionDigits: 2, currency }).format(value)
}
Vue.prototype.$formattedTwoDecimal = (value) => {
  if (!value) return null
  return (value / 1).toFixed(2)
}
Vue.prototype.$assets_balance = (value) => {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: 6 }).format(value)
}
Vue.prototype.$percentage = (value) => {
  return (value || 0) + '%'
}

Vue.prototype.$uniqueBy = (array, key) => {
  return [...new Map(array.map(item => [item[key], item])).values()]
}

Vue.prototype.$toFixed = (value, digits = 2) => {
  if ([undefined, null].includes(value)) return value
  if (typeof value === 'string') value = parseFloat(value)
  return value.toFixed(digits)
}

Vue.prototype.$balance_without_symbol = (value) => {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value)
}

Vue.prototype.$yesNo = (value) => {
  return value ? 'Yes' : 'No'
}

Vue.prototype.$fileSize = (size) => {
  size = parseInt(size)
  if (!size) return '0 kB'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

Vue.prototype.$country = (value) => window.countriesOptions[value]

window.errorMessage = 'There was an error. Try again later or contact our support.'

window.intermediaryBankIdentifierOptions = [
  {
    id: 'routing',
    text: 'Routing Number'
  },
  {
    id: 'swift',
    text: 'SWIFT Code'
  }
]

window.accountOptions = [
  {
    id: 'personal',
    text: 'Personal Checking'
  },
  {
    id: 'business',
    text: 'Business Checking'
  }
]

window.bankTypeOptions = [
  {
    id: 'checking',
    text: 'Checking'
  },
  {
    id: 'savings',
    text: 'Savings'
  }
]

window.yearsOptions = {}
window.currentYear = (new Date()).getFullYear()
for (let i = window.currentYear - 80; i <= window.currentYear; i++) { window.yearsOptions[i] = i }

window.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

window.sexes = {
  MALE: 'Male',
  FEMALE: 'Female'
}

window.idTypes = {
  PASSPORT: 'Passport',
  DRIVERS_LICENSE: 'Drivers License'
}

window.depositTypeOptions = [
  {
    id: 'Funds',
    text: 'Funds'
  },
  {
    id: 'Units',
    text: 'Units'
  }
]

window.accountTypeOptions = [
  {
    id: 'Business',
    text: 'Business'
  },
  {
    id: 'Personal',
    text: 'Personal'
  }
]

window.quoteTypesOptions = [
  {
    id: 'Buy',
    text: 'Buy'
  },
  {
    id: 'Sell',
    text: 'Sell'
  }
]

window.sendingToOptions = [
  {
    id: 'WalletAddress',
    text: 'Wallet Address'
  },
  {
    id: 'AccountTransfer',
    text: 'Account Transfer'
  }
]

window.currencyOptions = [
  {
    id: 'USD',
    text: 'USD'
  },
  {
    id: 'EUR',
    text: 'EUR',
    disabled: true
  }
]

window.cardStatuses = {
  Complete: 'Pending',
  Open: 'Open',
  pendingActivation: 'Pending Activation',
  active: 'Active',
  inactive: 'Inactive',
  canceled: 'Canceled'
}

window.transactionTypes = {
  ach: 'ACH',
  domestic_wire: 'Domestic Wire',
  international_wires: 'International Wire',
  acc_to_acc: 'Account Transfer',
  DebitCard: 'Debit Card',
  System: 'System'
}

window.transactionTypeText = {
  checks: 'Check',
  ach: 'ACH',
  domestic_wire: 'Domestic Wire',
  domesticWire: 'Domestic Wire',
  international_wires: 'International Wire',
  internationalWire: 'International Wire',
  acc_to_acc: 'Account Transfer',
  Asset: 'Asset',
  Trade: 'Trade',
  System: 'System',
  DebitCard: 'Debit Card'
}

window.transactionStatuses = {
  Review: 'Review',
  Pending: 'Pending',
  Onhold: 'On Hold',
  Complete: 'Complete',
  Cancelled: 'Cancelled',
  Declined: 'Declined',
  Refund: 'Refund',
  Reversed: 'Reversed',
  Error: 'Error'
}

window.quoteStatues = {
  New: 'New',
  pending: 'Pending',
  executed_pending_settlement: 'Pending',
  Review: 'Review',
  'On Hold': 'On Hold',
  Denied: 'Denied',
  executed: 'Executed',
  settled: 'Settled',
  complete: 'Complete',
  cancelled: 'Cancelled',
  Expired: 'Expired',
  Error: 'Error',
  failed: 'Failed'
}

window.quoteStatusColors = {
  New: 'success',
  pending: 'warning',
  executed_pending_settlement: 'warning',
  Review: 'warning',
  'On Hold': 'warning',
  Denied: 'danger',
  executed: 'success',
  settled: 'success',
  complete: 'info',
  cancelled: 'info',
  Expired: 'info',
  Error: 'info',
  failed: 'info'
}

window.currencyTypes = {
  Funds: 'Funds',
  Assets: 'Assets'
}

window.entryTypes = {
  Deposit: 'Deposit',
  Withdrawal: 'Withdrawal'
}

window.countries = [
  {
    id: 'CA',
    text: 'Canada'
  },
  {
    id: 'US',
    text: 'United States'
  },
  {
    id: 'AF',
    text: 'Afghanistan'
  },
  {
    id: 'AX',
    text: 'Åland Islands'
  },
  {
    id: 'AL',
    text: 'Albania'
  },
  {
    id: 'DZ',
    text: 'Algeria'
  },
  {
    id: 'AS',
    text: 'American Samoa'
  },
  {
    id: 'AD',
    text: 'Andorra'
  },
  {
    id: 'AO',
    text: 'Angola'
  },
  {
    id: 'AI',
    text: 'Anguilla'
  },
  {
    id: 'AQ',
    text: 'Antarctica'
  },
  {
    id: 'AG',
    text: 'Antigua and Barbuda'
  },
  {
    id: 'AR',
    text: 'Argentina'
  },
  {
    id: 'AM',
    text: 'Armenia'
  },
  {
    id: 'AW',
    text: 'Aruba'
  },
  {
    id: 'AU',
    text: 'Australia'
  },
  {
    id: 'AT',
    text: 'Austria'
  },
  {
    id: 'AZ',
    text: 'Azerbaijan'
  },
  {
    id: 'BH',
    text: 'Bahrain'
  },
  {
    id: 'BS',
    text: 'Bahamas'
  },
  {
    id: 'BD',
    text: 'Bangladesh'
  },
  {
    id: 'BB',
    text: 'Barbados'
  },
  {
    id: 'BY',
    text: 'Belarus'
  },
  {
    id: 'BE',
    text: 'Belgium'
  },
  {
    id: 'BZ',
    text: 'Belize'
  },
  {
    id: 'BJ',
    text: 'Benin'
  },
  {
    id: 'BM',
    text: 'Bermuda'
  },
  {
    id: 'BT',
    text: 'Bhutan'
  },
  {
    id: 'BO',
    text: 'Bolivia, Plurinational State of'
  },
  {
    id: 'BQ',
    text: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    id: 'BA',
    text: 'Bosnia and Herzegovina'
  },
  {
    id: 'BW',
    text: 'Botswana'
  },
  {
    id: 'BV',
    text: 'Bouvet Island'
  },
  {
    id: 'BR',
    text: 'Brazil'
  },
  {
    id: 'IO',
    text: 'British Indian Ocean Territory'
  },
  {
    id: 'BN',
    text: 'Brunei Darussalam'
  },
  {
    id: 'BG',
    text: 'Bulgaria'
  },
  {
    id: 'BF',
    text: 'Burkina Faso'
  },
  {
    id: 'BI',
    text: 'Burundi'
  },
  {
    id: 'KH',
    text: 'Cambodia'
  },
  {
    id: 'CM',
    text: 'Cameroon'
  },
  {
    id: 'CV',
    text: 'Cape Verde'
  },
  {
    id: 'KY',
    text: 'Cayman Islands'
  },
  {
    id: 'CF',
    text: 'Central African Republic'
  },
  {
    id: 'TD',
    text: 'Chad'
  },
  {
    id: 'CL',
    text: 'Chile'
  },
  {
    id: 'CN',
    text: 'China'
  },
  {
    id: 'CX',
    text: 'Christmas Island'
  },
  {
    id: 'CC',
    text: 'Cocos (Keeling) Islands'
  },
  {
    id: 'CO',
    text: 'Colombia'
  },
  {
    id: 'KM',
    text: 'Comoros'
  },
  {
    id: 'CG',
    text: 'Congo'
  },
  {
    id: 'CD',
    text: 'Congo, the Democratic Republic of the'
  },
  {
    id: 'CK',
    text: 'Cook Islands'
  },
  {
    id: 'CR',
    text: 'Costa Rica'
  },
  {
    id: 'CI',
    text: 'Côte d&#39;Ivoire'
  },
  {
    id: 'HR',
    text: 'Croatia'
  },
  {
    id: 'CU',
    text: 'Cuba'
  },
  {
    id: 'CW',
    text: 'Curaçao'
  },
  {
    id: 'CY',
    text: 'Cyprus'
  },
  {
    id: 'CZ',
    text: 'Czech Republic'
  },
  {
    id: 'DK',
    text: 'Denmark'
  },
  {
    id: 'DJ',
    text: 'Djibouti'
  },
  {
    id: 'DM',
    text: 'Dominica'
  },
  {
    id: 'DO',
    text: 'Dominican Republic'
  },
  {
    id: 'EC',
    text: 'Ecuador'
  },
  {
    id: 'EG',
    text: 'Egypt'
  },
  {
    id: 'SV',
    text: 'El Salvador'
  },
  {
    id: 'GQ',
    text: 'Equatorial Guinea'
  },
  {
    id: 'ER',
    text: 'Eritrea'
  },
  {
    id: 'EE',
    text: 'Estonia'
  },
  {
    id: 'ET',
    text: 'Ethiopia'
  },
  {
    id: 'FK',
    text: 'Falkland Islands (Malvinas)'
  },
  {
    id: 'FO',
    text: 'Faroe Islands'
  },
  {
    id: 'FJ',
    text: 'Fiji'
  },
  {
    id: 'FI',
    text: 'Finland'
  },
  {
    id: 'FR',
    text: 'France'
  },
  {
    id: 'GF',
    text: 'French Guiana'
  },
  {
    id: 'PF',
    text: 'French Polynesia'
  },
  {
    id: 'TF',
    text: 'French Southern Territories'
  },
  {
    id: 'GA',
    text: 'Gabon'
  },
  {
    id: 'GM',
    text: 'Gambia'
  },
  {
    id: 'GE',
    text: 'Georgia'
  },
  {
    id: 'DE',
    text: 'Germany'
  },
  {
    id: 'GH',
    text: 'Ghana'
  },
  {
    id: 'GI',
    text: 'Gibraltar'
  },
  {
    id: 'GR',
    text: 'Greece'
  },
  {
    id: 'GL',
    text: 'Greenland'
  },
  {
    id: 'GD',
    text: 'Grenada'
  },
  {
    id: 'GP',
    text: 'Guadeloupe'
  },
  {
    id: 'GU',
    text: 'Guam'
  },
  {
    id: 'GT',
    text: 'Guatemala'
  },
  {
    id: 'GG',
    text: 'Guernsey'
  },
  {
    id: 'GN',
    text: 'Guinea'
  },
  {
    id: 'GW',
    text: 'Guinea-Bissau'
  },
  {
    id: 'GY',
    text: 'Guyana'
  },
  {
    id: 'HT',
    text: 'Haiti'
  },
  {
    id: 'HM',
    text: 'Heard Island and McDonald Islands'
  },
  {
    id: 'VA',
    text: 'Holy See (Vatican City State)'
  },
  {
    id: 'HN',
    text: 'Honduras'
  },
  {
    id: 'HK',
    text: 'Hong Kong'
  },
  {
    id: 'HU',
    text: 'Hungary'
  },
  {
    id: 'IS',
    text: 'Iceland'
  },
  {
    id: 'IN',
    text: 'India'
  },
  {
    id: 'ID',
    text: 'Indonesia'
  },
  {
    id: 'IR',
    text: 'Iran, Islamic Republic of'
  },
  {
    id: 'IQ',
    text: 'Iraq'
  },
  {
    id: 'IE',
    text: 'Ireland'
  },
  {
    id: 'IM',
    text: 'Isle of Man'
  },
  {
    id: 'IL',
    text: 'Israel'
  },
  {
    id: 'IT',
    text: 'Italy'
  },
  {
    id: 'JM',
    text: 'Jamaica'
  },
  {
    id: 'JP',
    text: 'Japan'
  },
  {
    id: 'JE',
    text: 'Jersey'
  },
  {
    id: 'JO',
    text: 'Jordan'
  },
  {
    id: 'KZ',
    text: 'Kazakhstan'
  },
  {
    id: 'KE',
    text: 'Kenya'
  },
  {
    id: 'KI',
    text: 'Kiribati'
  },
  {
    id: 'KP',
    text: 'Korea, Democratic People&#39;s Republic of'
  },
  {
    id: 'KR',
    text: 'Korea, Republic of'
  },
  {
    id: 'KW',
    text: 'Kuwait'
  },
  {
    id: 'KG',
    text: 'Kyrgyzstan'
  },
  {
    id: 'LA',
    text: 'Lao People&#39;s Democratic Republic'
  },
  {
    id: 'LV',
    text: 'Latvia'
  },
  {
    id: 'LB',
    text: 'Lebanon'
  },
  {
    id: 'LS',
    text: 'Lesotho'
  },
  {
    id: 'LR',
    text: 'Liberia'
  },
  {
    id: 'LY',
    text: 'Libya'
  },
  {
    id: 'LI',
    text: 'Liechtenstein'
  },
  {
    id: 'LT',
    text: 'Lithuania'
  },
  {
    id: 'LU',
    text: 'Luxembourg'
  },
  {
    id: 'MO',
    text: 'Macao'
  },
  {
    id: 'MK',
    text: 'Macedonia, the Former Yugoslav Republic of'
  },
  {
    id: 'MG',
    text: 'Madagascar'
  },
  {
    id: 'MW',
    text: 'Malawi'
  },
  {
    id: 'MY',
    text: 'Malaysia'
  },
  {
    id: 'MV',
    text: 'Maldives'
  },
  {
    id: 'ML',
    text: 'Mali'
  },
  {
    id: 'MT',
    text: 'Malta'
  },
  {
    id: 'MH',
    text: 'Marshall Islands'
  },
  {
    id: 'MQ',
    text: 'Martinique'
  },
  {
    id: 'MR',
    text: 'Mauritania'
  },
  {
    id: 'MU',
    text: 'Mauritius'
  },
  {
    id: 'YT',
    text: 'Mayotte'
  },
  {
    id: 'MX',
    text: 'Mexico'
  },
  {
    id: 'FM',
    text: 'Micronesia, Federated States of'
  },
  {
    id: 'MD',
    text: 'Moldova, Republic of'
  },
  {
    id: 'MC',
    text: 'Monaco'
  },
  {
    id: 'MN',
    text: 'Mongolia'
  },
  {
    id: 'ME',
    text: 'Montenegro'
  },
  {
    id: 'MS',
    text: 'Montserrat'
  },
  {
    id: 'MA',
    text: 'Morocco'
  },
  {
    id: 'MZ',
    text: 'Mozambique'
  },
  {
    id: 'MM',
    text: 'Myanmar'
  },
  {
    id: 'NA',
    text: 'Namibia'
  },
  {
    id: 'NR',
    text: 'Nauru'
  },
  {
    id: 'NP',
    text: 'Nepal'
  },
  {
    id: 'NL',
    text: 'Netherlands'
  },
  {
    id: 'NC',
    text: 'New Caledonia'
  },
  {
    id: 'NZ',
    text: 'New Zealand'
  },
  {
    id: 'NI',
    text: 'Nicaragua'
  },
  {
    id: 'NE',
    text: 'Niger'
  },
  {
    id: 'NG',
    text: 'Nigeria'
  },
  {
    id: 'NU',
    text: 'Niue'
  },
  {
    id: 'NF',
    text: 'Norfolk Island'
  },
  {
    id: 'MP',
    text: 'Northern Mariana Islands'
  },
  {
    id: 'NO',
    text: 'Norway'
  },
  {
    id: 'OM',
    text: 'Oman'
  },
  {
    id: 'PK',
    text: 'Pakistan'
  },
  {
    id: 'PW',
    text: 'Palau'
  },
  {
    id: 'PS',
    text: 'Palestine, State of'
  },
  {
    id: 'PA',
    text: 'Panama'
  },
  {
    id: 'PG',
    text: 'Papua New Guinea'
  },
  {
    id: 'PY',
    text: 'Paraguay'
  },
  {
    id: 'PE',
    text: 'Peru'
  },
  {
    id: 'PH',
    text: 'Philippines'
  },
  {
    id: 'PN',
    text: 'Pitcairn'
  },
  {
    id: 'PL',
    text: 'Poland'
  },
  {
    id: 'PT',
    text: 'Portugal'
  },
  {
    id: 'PR',
    text: 'Puerto Rico'
  },
  {
    id: 'QA',
    text: 'Qatar'
  },
  {
    id: 'RE',
    text: 'Réunion'
  },
  {
    id: 'RO',
    text: 'Romania'
  },
  {
    id: 'RU',
    text: 'Russian Federation'
  },
  {
    id: 'RW',
    text: 'Rwanda'
  },
  {
    id: 'BL',
    text: 'Saint Barthélemy'
  },
  {
    id: 'SH',
    text: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    id: 'KN',
    text: 'Saint Kitts and Nevis'
  },
  {
    id: 'LC',
    text: 'Saint Lucia'
  },
  {
    id: 'MF',
    text: 'Saint Martin (French part)'
  },
  {
    id: 'PM',
    text: 'Saint Pierre and Miquelon'
  },
  {
    id: 'VC',
    text: 'Saint Vincent and the Grenadines'
  },
  {
    id: 'WS',
    text: 'Samoa'
  },
  {
    id: 'SM',
    text: 'San Marino'
  },
  {
    id: 'ST',
    text: 'Sao Tome and Principe'
  },
  {
    id: 'SA',
    text: 'Saudi Arabia'
  },
  {
    id: 'SN',
    text: 'Senegal'
  },
  {
    id: 'RS',
    text: 'Serbia'
  },
  {
    id: 'SC',
    text: 'Seychelles'
  },
  {
    id: 'SL',
    text: 'Sierra Leone'
  },
  {
    id: 'SG',
    text: 'Singapore'
  },
  {
    id: 'SX',
    text: 'Sint Maarten (Dutch part)'
  },
  {
    id: 'SK',
    text: 'Slovakia'
  },
  {
    id: 'SI',
    text: 'Slovenia'
  },
  {
    id: 'SB',
    text: 'Solomon Islands'
  },
  {
    id: 'SO',
    text: 'Somalia'
  },
  {
    id: 'ZA',
    text: 'South Africa'
  },
  {
    id: 'GS',
    text: 'South Georgia and the South Sandwich Islands'
  },
  {
    id: 'SS',
    text: 'South Sudan'
  },
  {
    id: 'ES',
    text: 'Spain'
  },
  {
    id: 'LK',
    text: 'Sri Lanka'
  },
  {
    id: 'SD',
    text: 'Sudan'
  },
  {
    id: 'SR',
    text: 'Suriname'
  },
  {
    id: 'SJ',
    text: 'Svalbard and Jan Mayen'
  },
  {
    id: 'SZ',
    text: 'Swaziland'
  },
  {
    id: 'SE',
    text: 'Sweden'
  },
  {
    id: 'CH',
    text: 'Switzerland'
  },
  {
    id: 'SY',
    text: 'Syrian Arab Republic'
  },
  {
    id: 'TW',
    text: 'Taiwan, Province of China'
  },
  {
    id: 'TJ',
    text: 'Tajikistan'
  },
  {
    id: 'TZ',
    text: 'Tanzania, United Republic of'
  },
  {
    id: 'TH',
    text: 'Thailand'
  },
  {
    id: 'TL',
    text: 'Timor-Leste'
  },
  {
    id: 'TG',
    text: 'Togo'
  },
  {
    id: 'TK',
    text: 'Tokelau'
  },
  {
    id: 'TO',
    text: 'Tonga'
  },
  {
    id: 'TT',
    text: 'Trinidad and Tobago'
  },
  {
    id: 'TN',
    text: 'Tunisia'
  },
  {
    id: 'TR',
    text: 'Turkey'
  },
  {
    id: 'TM',
    text: 'Turkmenistan'
  },
  {
    id: 'TC',
    text: 'Turks and Caicos Islands'
  },
  {
    id: 'TV',
    text: 'Tuvalu'
  },
  {
    id: 'UG',
    text: 'Uganda'
  },
  {
    id: 'UA',
    text: 'Ukraine'
  },
  {
    id: 'AE',
    text: 'United Arab Emirates'
  },
  {
    id: 'GB',
    text: 'United Kingdom'
  },
  {
    id: 'UM',
    text: 'United States Minor Outlying Islands'
  },
  {
    id: 'UY',
    text: 'Uruguay'
  },
  {
    id: 'UZ',
    text: 'Uzbekistan'
  },
  {
    id: 'VU',
    text: 'Vanuatu'
  },
  {
    id: 'VE',
    text: 'Venezuela, Bolivarian Republic of'
  },
  {
    id: 'VN',
    text: 'Viet Nam'
  },
  {
    id: 'VG',
    text: 'Virgin Islands, British'
  },
  {
    id: 'VI',
    text: 'Virgin Islands, U.S.'
  },
  {
    id: 'WF',
    text: 'Wallis and Futuna'
  },
  {
    id: 'EH',
    text: 'Western Sahara'
  },
  {
    id: 'YE',
    text: 'Yemen'
  },
  {
    id: 'ZM',
    text: 'Zambia'
  },
  {
    id: 'ZW',
    text: 'Zimbabwe'
  }
]

window.countriesOptions = window.countries.reduce((acc, country) => {
  acc[country.id] = country.text
  return acc
}, {})

window.states = {
  CA: [
    {
      id: 'AB',
      text: 'Alberta'
    },
    {
      id: 'BC',
      text: 'British Columbia'
    },
    {
      id: 'MB',
      text: 'Manitoba'
    },
    {
      id: 'NB',
      text: 'New Brunswick'
    },
    {
      id: 'NL',
      text: 'Newfoundland'
    },
    {
      id: 'NS',
      text: 'Nova Scotia'
    },
    {
      id: 'NT',
      text: 'Northwest Territories'
    },
    {
      id: 'NU',
      text: 'Nunavut'
    },
    {
      id: 'ON',
      text: 'Ontario'
    },
    {
      id: 'PE',
      text: 'Prince Edward Island'
    },
    {
      id: 'QC',
      text: 'Quebec'
    },
    {
      id: 'SK',
      text: 'Saskatchewan'
    },
    {
      id: 'YT',
      text: 'Yukon Territories'
    }
  ],
  US: [
    {
      id: 'AL',
      text: 'Alabama'
    },
    {
      id: 'AK',
      text: 'Alaska'
    },
    {
      id: 'AS',
      text: 'American Samoa'
    },
    {
      id: 'AZ',
      text: 'Arizona'
    },
    {
      id: 'AR',
      text: 'Arkansas'
    },
    {
      id: 'CA',
      text: 'California'
    },
    {
      id: 'CO',
      text: 'Colorado'
    },
    {
      id: 'CT',
      text: 'Connecticut'
    },
    {
      id: 'DE',
      text: 'Delaware'
    },
    {
      id: 'DC',
      text: 'District of Columbia'
    },
    {
      id: 'FM',
      text: 'Federated States of Micronesia'
    },
    {
      id: 'FL',
      text: 'Florida'
    },
    {
      id: 'GA',
      text: 'Georgia'
    },
    {
      id: 'GU',
      text: 'Guam'
    },
    {
      id: 'HI',
      text: 'Hawaii'
    },
    {
      id: 'ID',
      text: 'Idaho'
    },
    {
      id: 'IL',
      text: 'Illinois'
    },
    {
      id: 'IN',
      text: 'Indiana'
    },
    {
      id: 'IA',
      text: 'Iowa'
    },
    {
      id: 'KS',
      text: 'Kansas'
    },
    {
      id: 'KY',
      text: 'Kentucky'
    },
    {
      id: 'LA',
      text: 'Louisiana'
    },
    {
      id: 'ME',
      text: 'Maine'
    },
    {
      id: 'MH',
      text: 'Marshall Islands'
    },
    {
      id: 'MD',
      text: 'Maryland'
    },
    {
      id: 'MA',
      text: 'Massachusetts'
    },
    {
      id: 'MI',
      text: 'Michigan'
    },
    {
      id: 'MN',
      text: 'Minnesota'
    },
    {
      id: 'MS',
      text: 'Mississippi'
    },
    {
      id: 'MO',
      text: 'Missouri'
    },
    {
      id: 'MT',
      text: 'Montana'
    },
    {
      id: 'NE',
      text: 'Nebraska'
    },
    {
      id: 'NV',
      text: 'Nevada'
    },
    {
      id: 'NH',
      text: 'New Hampshire'
    },
    {
      id: 'NJ',
      text: 'New Jersey'
    },
    {
      id: 'NM',
      text: 'New Mexico'
    },
    {
      id: 'NY',
      text: 'New York'
    },
    {
      id: 'NC',
      text: 'North Carolina'
    },
    {
      id: 'ND',
      text: 'North Dakota'
    },
    {
      id: 'MP',
      text: 'Northern Mariana Islands'
    },
    {
      id: 'OH',
      text: 'Ohio'
    },
    {
      id: 'OK',
      text: 'Oklahoma'
    },
    {
      id: 'OR',
      text: 'Oregon'
    },
    {
      id: 'PW',
      text: 'Palau'
    },
    {
      id: 'PA',
      text: 'Pennsylvania'
    },
    {
      id: 'PR',
      text: 'Puerto Rico'
    },
    {
      id: 'RI',
      text: 'Rhode Island'
    },
    {
      id: 'SC',
      text: 'South Carolina'
    },
    {
      id: 'SD',
      text: 'South Dakota'
    },
    {
      id: 'TN',
      text: 'Tennessee'
    },
    {
      id: 'TX',
      text: 'Texas'
    },
    {
      id: 'UT',
      text: 'Utah'
    },
    {
      id: 'VT',
      text: 'Vermont'
    },
    {
      id: 'VI',
      text: 'Virgin Islands'
    },
    {
      id: 'VA',
      text: 'Virginia'
    },
    {
      id: 'WA',
      text: 'Washington'
    },
    {
      id: 'WV',
      text: 'West Virginia'
    },
    {
      id: 'WI',
      text: 'Wisconsin'
    },
    {
      id: 'WY',
      text: 'Wyoming'
    }
  ],
  AR: [
    {
      id: 'BA',
      text: 'Buenos Aires'
    },
    {
      id: 'DF',
      text: 'Ciudad Autónoma de Buenos Aires'
    },
    {
      id: 'CT',
      text: 'Catamarca'
    },
    {
      id: 'CC',
      text: 'Chaco'
    },
    {
      id: 'CH',
      text: 'Chubut'
    },
    {
      id: 'CB',
      text: 'Córdoba'
    },
    {
      id: 'CN',
      text: 'Corrientes'
    },
    {
      id: 'ER',
      text: 'Entre Ríos'
    },
    {
      id: 'FM',
      text: 'Formosa'
    },
    {
      id: 'JY',
      text: 'Jujuy'
    },
    {
      id: 'LP',
      text: 'La Pampa'
    },
    {
      id: 'LR',
      text: 'La Rioja'
    },
    {
      id: 'MZ',
      text: 'Mendoza'
    },
    {
      id: 'MN',
      text: 'Misiones'
    },
    {
      id: 'NQ',
      text: 'Neuquén'
    },
    {
      id: 'RN',
      text: 'Río Negro'
    },
    {
      id: 'SA',
      text: 'Salta'
    },
    {
      id: 'SJ',
      text: 'San Juan'
    },
    {
      id: 'SL',
      text: 'San Luis'
    },
    {
      id: 'SC',
      text: 'Santa Cruz'
    },
    {
      id: 'SF',
      text: 'Santa Fe'
    },
    {
      id: 'SE',
      text: 'Santiago del Estero'
    },
    {
      id: 'TF',
      text: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur'
    },
    {
      id: 'TM',
      text: 'Tucumán'
    }
  ]
}

window.statesOptions = window.states.CA.concat(window.states.US).reduce((acc, state) => {
  acc[state.id] = state.text
  return acc
}, {})

const toOptions = ['sexes', 'transactionTypes', 'transactionStatuses', 'currencyTypes', 'entryTypes', 'quoteStatues']
toOptions.forEach(name => {
  window[`${name}Options`] = Object.keys(window[name]).map(key => ({
    id: key,
    text: window[name][key]
  }))
})
