<template>
  <form-item
    :label="label"
    :validator="validator"
    class="w-full datepicker"
    :levitated="false">
    <template v-if="!showAsText">
      <div v-if="type === 'date-range'" class="date-range-picker">
        <el-date-picker
          v-model="from"
          v-bind="options"
          :disabled="disabled"
          :placeholder="placeholder || 'Start date'"
          :picker-options="pickerOptions"
          prefix-icon=" "
          value-format="yyyy-MM-dd" />
        <span class="spacer"></span>
        <el-date-picker
          v-model="to"
          v-bind="options"
          :disabled="disabled"
          :placeholder="placeholder || 'End date'"
          :picker-options="pickerOptions"
          prefix-icon=" "
          value-format="yyyy-MM-dd" />
      </div>
      <el-date-picker
        v-else
        v-model="val"
        v-bind="options"
        :disabled="disabled"
        :placeholder="placeholder || 'Select date'"
        :picker-options="pickerOptions"
        prefix-icon=" "
        value-format="yyyy-MM-dd" />
    </template>

    <span
      v-else
      class="textual">
      {{ val || 'N/A' }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    type: {
      type: String,
      default: 'date'
    }
  },

  data () {
    return {
      from: null,
      to: null,
      pickerOptions: {
        disabledDate (date) {
          return date > new Date()
        }
      }

    }
  },

  watch: {
    value: {
      handler () {
        if (this.type === 'date-range') {
          this.from = this.value ? this.value[0] : null
          this.to = this.value ? this.value[1] : null
        }
      },
      deep: true,
      immediate: true
    },

    from () {
      this.val = [this.from, this.to]
    },

    to () {
      this.val = [this.from, this.to]
    }
  }
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
  .date-range-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .spacer {
      margin: 0 10px;
    }
  }

  .datepicker::v-deep .el-input .el-input__inner {
    padding: 0 15px !important
  }
</style>
