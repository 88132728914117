<template>
  <el-drawer
    :title="title"
    :visible="true"
    :before-close="beforeClose"
    :size="size"
    :class="classes"
    destroy-on-close
    @close="$emit('close')">
    <div class="content">
      <slot />
    </div>
    <div
      v-if="$slots.footer"
      class="drawer__footer">
      <slot name="footer" />
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    size: {
      type: [Number, String],
      default: '100%'
    },

    beforeClose: {
      type: Function,
      default: (done) => done()
    },

    classes: {
      type: String,
      default: null
    }
  }
}
</script>
