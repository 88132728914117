<template>
  <div>
    <ms-select
      v-if="field.type === 'select'"
      v-model="form[field.key]"
      v-bind="field.props || {}"
      :label="field.label"
      :options="field.options"
      clearable
      filterable
      full />

    <ms-input
      v-if="field.type === 'input'"
      v-model="form[field.key]"
      v-bind="field.props || {}"
      :label="field.label"
      full />

    <ms-date-picker
      v-if="['range_datepicker', 'datepicker'].includes(field.type)"
      v-model="date"
      v-bind="field.props || {}"
      :label="field.label"
      :type="field.type === 'range_datepicker' ? 'date-range' : 'date'" />

    <div v-if="error" class="error-text">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    field: {
      type: Object,
      required: true
    },

    error: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      date: null
    }
  },

  computed: {
    isDateRange () {
      return this.field.type === 'range_datepicker'
    },

    form: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  watch: {
    date (date) {
      if (!this.isDateRange) return
      if (!this.form[this.field.key]) this.form[this.field.key] = {}

      this.form[this.field.key].from = date ? date[0] : null
      this.form[this.field.key].to = date ? date[1] : null
    }
  },

  created () {
    if (this.form?.[this.field.key] && this.isDateRange) {
      this.date = [this.form[this.field.key].from, this.form[this.field.key].to]
    }
  }
}
</script>
