<template>
  <drawer
    :value="true"
    title="Filters"
    classes="gray"
    destroy-on-close
    @close="$emit('close')">

    <div
      v-for="field in map"
      :key="field.key"
      class="form-item">
      <filter-field v-model="form" :field="field" :error="errors ? errors[field.key] : null" />
    </div>

    <template #footer>
      <el-button
        class="button cancel-button mr-10"
        @click="$emit('close')"
        round>
        Cancel
      </el-button>
      <el-button
        class="button gradient"
        type="success"
        @click="filter()"
        round>
        Apply
      </el-button>
    </template>
  </drawer>
</template>

<script>
import moment from 'moment'

import FilterField from './FilterField'
import Drawer from '@/components/common/ui/Drawer'

export default {
  components: {
    Drawer,
    FilterField
  },

  props: {
    filters: {
      type: Object,
      default: null
    },

    map: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      form: {},
      errors: null
    }
  },

  created () {
    this.setup()
  },

  methods: {
    setup () {
      const form = {}
      this.map.forEach(fieldMap => {
        const val = this.filters?.[fieldMap.key]
        if (val) form[fieldMap.key] = this.getFilterValue(val, fieldMap)
        else form[fieldMap.key] = this.getFilterInitialValue(fieldMap)
      })
      this.form = form
    },

    getFilterValue (val, fieldMap) {
      return typeof val === 'object' ? JSON.parse(JSON.stringify(val)) : val
    },

    clear () {
      const form = {}
      this.map.forEach(field => {
        form[field.key] = null
      })
      this.form = form
    },

    getFilterInitialValue (field) {
      if (field.type === 'range_datepicker') {
        return {
          from: null,
          to: null
        }
      }

      return null
    },

    validate () {
      const errors = {}
      this.map.filter(field => !!field.validation).forEach(field => {
        const value = this.form[field.key]
        const error = field.validation(value)
        if (error) {
          errors[field.key] = error
        }
      })

      this.errors = errors

      return !Object.keys(errors).length
    },

    filter () {
      if (this.validate()) {
        this.$emit('apply', JSON.parse(JSON.stringify(this.form)))
      }
    },

    customDateFormatter (date) {
      return moment(date).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-item {
    display: block;
    margin-bottom: 15px;
  }

  .cancel-button {
    max-width: 35%;
  }
</style>
