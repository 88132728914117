import axios from 'axios'
import moment from 'moment'

const state = {
  user: null,
  system: null,
  themes: [],
  customEnv: null,
  currencies: [],
  version: null,
  error: null,
  systemMessage: [],
  readed: [],
  discarded: [],
  systemFeatures: {},
  maintenance: false,
  loaded: false,
  colors: {
    white: '#fff',
    black: '#000',
    primary: '#18BC9C',
    link: '#139ADA',
    bg: '#F4F6F8',
    surface: '#FFFFFF',
    title: '#29282A',
    text: '#616F7B',
    text200: '#99AABB',
    danger: '#D92930',
    success: '#18BC9C',
    info: '#139ADA'
  }
}

const getters = {
  theme: (state) => state.themes?.find(item => item.activated === '1')
}

const actions = {
  async loadData ({ state, commit }, force = false) {
    if (state.loaded && !force) return state.user

    try {
      const { data } = await axios.get('/')
      commit('setData', data)
      commit('setLoaded', true)
      return data.user
    } catch (error) {
      console.error(error)
      commit('setError', error)
      return Promise.reject(error)
    }
  },

  async register (_, payload) {
    try {
      const response = await axios.post('/register', payload)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async registerUpload ({ commit }, { file, params = null }) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('returnObj', true)

    if (params) {
      for (const field in params) formData.append(field, params[field])
    }

    const response = await axios.post(`/register`, formData)
    return response.data
  },


  async login ({ commit }, payload) {
    const { data } = await axios.post('/login', payload)
    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
    if (data.token)
      localStorage.setItem('token', data.token)
    commit('setUser', data.user)
    return data.success ? Promise.resolve(data.user) : Promise.reject(data)
  },

  async sendPwReset({ commit }, { username }) {
    const { data } = await axios.get('/user-exists?username='+encodeURIComponent(username))
    if (data && data.user) {
      await axios.post('/User/'+data.user.id+'/ResetPassword', {app: true})
    }
  },

  async resetPw({ commit }, { username, password, newPassword }) {
    return (await axios.post('/login/resetpw', {username, password, newPassword})).data
  },


  async logout ({ commit }) {
    try {
      await axios.post('/logout')
      commit('logout')
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  setData (state, data) {
    state.error = null
    state.user = data.currentUser
    state.system = data.system
    state.themes = data.themes
    state.version = data.version
    state.customEnv = data.customEnv
    state.currencies = data.currencies
    state.systemMessage = data.systemMessage
    state.maintenance = data.maintenance
    state.readed = data.readed
    state.discarded = data.discarded
    state.systemFeatures = data.systemFeatures

    const selectedTheme = data.themes?.find(item => item.activated === '1')
    if (selectedTheme) {
      state.colors.primary = selectedTheme.mainColor
      state.colors.title = selectedTheme.pageHeadersColor
    }
  },

  addReded (state, readed) {
    state.readed.push(readed)
  },

  addDiscarded (state, discarded) {
    state.discarded.push(discarded)
  },

  setError (state, error) {
    state.error = error
  },

  setUser (state, user) {
    state.user = user
  },

  setServerTimeDiff (state, serverNow) {
    state.serverTimeDiff = Math.round((moment().unix() - moment(serverNow).unix()) / 60) * 60
  },

  logout (state) {
    state.user = null
    delete axios.defaults.headers.common.Authorization
    localStorage.removeItem('token')
  },

  setLoaded (state, data) {
    state.loaded = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
