import Vue from 'vue'

Vue.filter('date', window.formatDate)

Vue.filter('distance', (value) => {
  return ![null, undefined, NaN].includes(value) ? `${value.toFixed(1)} km` : 'N/A'
})

Vue.filter('money', (value, currency) => {
  currency = currency || 'USD'
  value = value || 0
  return Intl.NumberFormat('en-US', { style: 'currency', minimumFractionDigits: 2, currency }).format(value)
})

Vue.filter('balance_without_symbol', (value) => {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value)
})

Vue.filter('assets_balance', (value, decimals = 6) => {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: decimals }).format(value)
})

Vue.filter('accountNumber', (value) => {
  return `****${value.substr(value.length - 3)}`
})

Vue.filter('uppercase', (value) => value.toUpperCase())

Vue.filter('capitalize', (value) => {
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter('yesNo', (value) => value ? 'Yes' : 'No')

Vue.filter('fileSize', (size) => {
  size = parseInt(size)
  if (!size) return '0 kB'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
})

Vue.filter('formattedTwoDecimal', (value) => {
  if (value) { return (value / 1).toFixed(2) }
})

Vue.filter('country', (value) => window.countriesOptions[value])

Vue.filter('quoteStatus', (value) => window.quoteStatues[value])

Vue.filter('state', (value) => {
  if (!value) return 'N/A'
  const states = window.states.CA.concat(window.states.US)
  const state = states.find(item => item.id === value)
  return state?.text || value
})
