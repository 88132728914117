<template>
  <div>
    <portal to="filter-button">
      <el-button
        v-if="filtersMap"
        size="medium"
        :type="hasAppliedFilters ? 'info' : undefined"
        icon="el-icon-s-operation"
        @click="openFilters = true"
        circle />
    </portal>

    <portal to="applied-filters">
      <applied-filters
        v-if="filters"
        :filters="filters"
        :map="filtersMap"
        @remove="removeFilter($event)"
        @clear="clearFilters()" />
    </portal>

    <template v-if="openFilters">
      <filters-drawer
        :filters="filters"
        :map="filtersMap"
        @close="openFilters = false"
        @apply="applyFilters" />
    </template>
  </div>
</template>

<script>
import FiltersDrawer from './FiltersDrawer'
import AppliedFilters from './AppliedFilters'

export default {
  components: {
    FiltersDrawer,
    AppliedFilters
  },

  props: {
    filters: {
      type: Object,
      default: null
    },

    filtersMap: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      openFilters: false
    }
  },

  computed: {
    hasAppliedFilters () {
      return this.getHasAppliedFilters(this.filters)
    }
  },

  methods: {
    getHasAppliedFilters (filters) {
      if (!filters) return false
      return Object.keys(filters).some(key => !this.isEmptyFilter(filters[key], key))
    },

    isEmptyFilter (value, key) {
      if (value === undefined || value === null) return true

      const mapItem = this.filtersMap.find(item => item.key === key)

      if (mapItem?.type === 'range_datepicker') {
        return value ? !(value.from || value.to) : true
      }

      return !value
    },

    applyFilters (filters) {
      this.$emit('apply', this.getHasAppliedFilters(filters) ? filters : null)
      this.openFilters = false
    },

    removeFilter (filter) {
      const newFilters = JSON.parse(JSON.stringify(this.filters))
      newFilters[filter.key] = undefined
      this.applyFilters(newFilters)
    },

    clearFilters () {
      this.applyFilters({})
    }
  }
}
</script>
