<template>
  <form-item
    :label="label"
    :validator="validator"
    class="w-full">
    <div class="el-input">
      <div class="el-input__inner">
        <input
          v-show="!file"
          ref="upload"
          type="file"
          :disabled="disabled"
          @blur="$emit('blur')"
          @change="uploadFile($event)" />

        <div
          v-if="file"
          class="selected-file">
          <span class="text">{{ file.name }}</span>

          <div class="icons">
            <i
              class="el-icon-upload2"
              @click="reupload()" />
            <i
              class="el-icon-delete"
              @click="clear()" />
          </div>
        </div>
      </div>
    </div>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'
import helperMixin from '../../helper-mixin.js'

export default {
  mixins: [inputMixin, helperMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    classes: {
      type: Object,
      default: null
    },

    onlyImages: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      file: null
    }
  },

  watch: {
    value (value) {
      if (!value) this.file = null
    }
  },

  created () {
    if (this.value) this.file = this.value
  },

  methods: {
    reupload () {
      this.$refs.upload.click()
    },

    clear () {
      this.file = null
      this.$refs.upload.value = null
      this.$emit('input', this.file)
    },

    uploadFile ($e) {
      this.file = $e?.target?.files?.[0]
      if (this.onlyImages && !this.isImage(this.file)) {
        this.$message.error('Only image files are allowed. (.jpg, .jpeg, .png, .gif, .webp)')
        this.clear()
        return
      }
      this.$emit('input', this.file)
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-file {
  display: flex;
  justify-content: space-between;
  .text {
    overflow: hidden;
    flex-shrink: 1;
    display: block;
    width: 100%;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icons {
    flex-grow: 1;
    white-space: nowrap;
    font-size: 18px;
    i {
      cursor: pointer;
      &:hover {
        color: #409EFF;
      }
      &:last-of-type {
        margin-left: 5px;
      }
    }
  }
}
</style>
