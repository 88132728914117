import Vue from 'vue'
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import vueImageGallery from 'vue-image-gallery'

import App from './App.vue'
import router from './router'
import store from './store'

import './lib/config'
import './lib/globals'
import './lib/filters'
import './lib/components'

import './assets/scss/main.scss'

Vue.use(Vuelidate)
Vue.use(PortalVue)
Vue.use(vueImageGallery)
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
