<template>
  <div class="data-list-item" :class="{ 'mt-40': !!item.spacer }">
    <label v-if="item.subtitle" class="subtitle">{{ item.subtitle }}</label>
    <label v-if="item.label">{{ item.label }}:</label>
    <span v-if="item.html" v-html="item.html" />
    <span v-if="item.text !== undefined">{{ item.text || emptyText }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    emptyText: {
      type: String,
      default: null
    }
  }
}
</script>
