<template>
  <div class="empty-box">
    <div>
      <component :is="icon" />

      <div class="text">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import EmptyIcon from '@/assets/images/icons/empty.svg'

export default {
  props: {
    icon: {
      type: Object,
      default: () => EmptyIcon
    }
  }
}
</script>

<style lang="scss" scoped>
  .empty-box {
    text-align: center;
    width: 100%;
    padding: 80px 30px;
    box-sizing: border-box;
    img {
      margin-bottom: 20px;
    }
    .text {
      color: #29282A;
      font-size: 16px;
      line-height: 17px;
      font-weight: 700;
      margin-top: 22px;
    }
  }
</style>
