<template>
  <div class="ms-modal" :class="modalClasses" @click="close()">
    <div class="box" @click.stop>
      <div class="close" @click="close()">
        <CloseSvg :fill="closeIconColor" />
      </div>
      <div v-if="title" class="header">
        {{ title }}
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import CloseSvg from '@/assets/images/icons/close.svg'

export default {
  props: {
    title: {
      type: String,
      default: null
    },

    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'success', 'error'].includes(value)
    },

    gray: {
      type: Boolean,
      default: false
    }
  },

  components: {
    CloseSvg
  },

  computed: {
    modalClasses () {
      const classes = [this.type]
      if (this.gray) classes.push('gray')
      return classes
    },

    closeIconColor () {
      if (['success', 'error'].includes(this.type)) return this.$colors.white
      return this.$colors.black
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .ms-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
      width: 100%;
      background-color: $white;
      border-radius: 18px;
      overflow: hidden;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .header {
        padding: 20px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;

      }

      .content {
        height: 100%;
        padding: 20px;
        flex: 1;
        overflow-y: auto;
      }
    }

    &.success {
      .box .header {
        background-color: $success;
        color: $white;
      }
    }

    &.error {
      .box .header {
        background-color: $danger;
        color: $white;
      }
    }

    &.gray .box {
      background-color: $bg_dark;
    }
  }
</style>
