<template>
  <form-item
    :label="label"
    :value="val"
    :is-focused="isFocused"
    :input-id="inputId"
    :validator="validator">
    <el-select
      v-if="!showAsText"
      v-model="val"
      :id="inputId"
      :disabled="disabled"
      :placeholder="!label && placeholder ? placeholder : null"
      :clearable="clearable"
      :filterable="filterable"
      @focus="onFocus()"
      @blur="onBlur()"
      @change="onBlur()"
      class="w-full">
      <template v-if="isGroup">
        <el-option-group
          v-for="group in options"
          :key="group.label"
          :label="group.label">
          <el-option
            v-for="item, index in group.options"
            :key="item.id + index"
            :label="item.text"
            :value="item.id" />
        </el-option-group>
      </template>
      <template v-else>
        <el-option
          v-for="option in options"
          :key="option.id"
          :disabled="option.disabled"
          :label="option.text"
          :value="option.id" />
      </template>
    </el-select>
    <span
      v-else
      class="textual">
      {{ textualValue }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },

    filterable: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },

    isGroup: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    textualValue () {
      const item = this.options.find(option => option.id === this.val)
      return item ? item.text : 'N/A'
    }
  }
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
</style>
