import Vue from 'vue'

import Modal from '../components/common/ui/Modal'
import Panel from '../components/common/ui/Panel'
import Empty from '../components/common/ui/Empty'
import Drawer from '../components/common/ui/Drawer'
import Filters from '../components/filters/Filters'
import ErrorBox from '../components/common/ui/ErrorBox'
import Input from '../components/common/ui/forms/Input'
import DataList from '../components/common/ui/DataList'
import Select from '../components/common/ui/forms/Select'
import Checkbox from '../components/common/ui/forms/Checkbox'
import Textarea from '../components/common/ui/forms/Textarea'
import DatePicker from '../components/common/ui/forms/DatePicker'
import UploadInput from '../components/common/ui/forms/UploadInput'

Vue.component('panel', Panel)
Vue.component('empty', Empty)
Vue.component('ms-modal', Modal)
Vue.component('ms-input', Input)
Vue.component('ms-select', Select)
Vue.component('ms-filters', Filters)
Vue.component('ms-drawer', Drawer)
Vue.component('error-box', ErrorBox)
Vue.component('data-list', DataList)
Vue.component('ms-checkbox', Checkbox)
Vue.component('ms-textarea', Textarea)
Vue.component('ms-date-picker', DatePicker)
Vue.component('ms-upload-input', UploadInput)
