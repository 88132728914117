const state = {
  title: null,
  back: null,
  tabs: []
}

const mutations = {
  setTabs (state, tabs) {
    state.tabs = tabs
  },

  setPageInfo (state, data) {
    document.title = data.title
    state.title = data.title || null
    state.back = data.back
  }
}

export default {
  state,
  mutations,
  namespaced: true
}
