export default [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Dashboard' },
    meta: { auth: true },
    component: () => import('@/views/MainView.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: () => import('@/views/transactions/Transactions.vue')
      },
      {
        path: 'transactions/:id',
        name: 'TransactionView',
        component: () => import('@/views/transactions/TransactionView.vue')
      },
      {
        path: 'trade-desk',
        name: 'TradeDesk',
        component: () => import('@/views/transactions/TradeDesk.vue')
      },
      {
        path: 'trade-desk/:id',
        name: 'TradeDeskView',
        component: () => import('@/views/transactions/TradeDeskView.vue')
      },
      {
        path: 'statements',
        name: 'Statements',
        component: () => import('@/views/transactions/Statements.vue')
      },
      {
        path: 'templates',
        name: 'Templates',
        component: () => import('@/views/transactions/Templates.vue')
      },
      {
        path: 'withdrawal',
        name: 'Withdrawal',
        component: () => import('@/views/withdrawal/Withdrawal.vue')
      },
      {
        path: 'account-link',
        name: 'AccountLink',
        component: () => import('@/views/AccountLink.vue')
      },
      {
        path: 'deposit-payments-details',
        name: 'DepositPaymentsDetails',
        component: () => import('@/views/DepositPaymentsDetails.vue')
      },
      {
        path: 'bank-lookup',
        name: 'BankLookup',
        component: () => import('@/views/BankLookup.vue')
      },
      {
        path: 'support',
        name: 'Support',
        redirect: { name: 'SupportDashboard' },
        component: () => import('@/views/ListingsView.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'SupportDashboard',
            component: () => import('@/views/support/Support.vue')
          },
          {
            path: 'faq',
            name: 'FAQ',
            component: () => import('@/views/support/FAQ.vue')
          },
          {
            path: 'tickets',
            name: 'SupportTickets',
            component: () => import('@/views/support/SupportTickets.vue')
          },
          {
            path: 'file-transfer',
            name: 'FileTransfer',
            component: () => import('@/views/support/FileTransfer.vue')
          }
        ]
      },
      {
        path: 'my-profile',
        name: 'MyProfile',
        redirect: { name: 'MyProfileDashboard' },
        component: () => import('@/views/ListingsView.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'MyProfileDashboard',
            component: () => import('@/views/profile/MyProfile.vue')
          },
          {
            path: 'accounts',
            name: 'MyAccounts',
            component: () => import('@/views/profile/MyAccounts.vue')
          },
          {
            path: 'settings',
            name: 'MySettings',
            component: () => import('@/views/profile/MySettings.vue')
          },
          {
            path: 'access',
            name: 'MyAccess',
            component: () => import('@/views/profile/MyAccess.vue')
          },
          {
            path: 'change-password',
            name: 'ChangePassword',
            component: () => import('@/views/profile/ChangePassword.vue')
          }
        ]
      },
      {
        path: 'profile/settings',
        name: 'ProfileSettings',
        component: () => import('@/views/profile/ProfileSettings.vue')
      },
      {
        path: 'messages',
        name: 'Messages',
        component: () => import('@/views/messages/Messages.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { auth: false },
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { auth: false },
    component: () => import('../views/RegisterForm2.vue')
  },
  {
    path: '/activation',
    name: 'Activation',
    meta: { auth: false },
    component: () => import('../views/Activation.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: { auth: false },
    component: () => import('../views/auth/ForgotPassword.vue')
  },
  {
    path: '*',
    component: () => import('@/components/common/pages/404.vue')
  }
]
