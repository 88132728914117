<template>
  <div
    class="panel"
    :class="{ scrollable }">
    <div
      v-if="title"
      class="header"
      :class="{ border: isContentVisible }"
      @click="expandOnClick()">
      <div>
        <h3>{{ title }}</h3>
        <h4 v-if="subtitle">{{ subtitle }}</h4>
      </div>

      <div class="actions">
        <span @click.stop>
          <slot name="actions" @click.stop />
        </span>

        <el-button
          v-if="collapsable"
          class="ml-5"
          size="mini"
          :icon="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          circle />
      </div>
    </div>

    <el-collapse-transition>
      <div v-show="isContentVisible">
        <div v-if="$slots.default" class="content">
          <slot />
        </div>

        <div
          v-if="$slots.footer"
          class="footer">
          <slot name="footer" />
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    },

    collapsable: {
      type: Boolean,
      default: false
    },

    initiallyExpanded: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expanded: this.initiallyExpanded
    }
  },

  computed: {
    isContentVisible () {
      return !this.collapsable || this.expanded
    }
  },

  methods: {
    expandOnClick () {
      if (this.collapsable) {
        this.expanded = !this.expanded
      }
    }
  }
}
</script>
